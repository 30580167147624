import React from 'react';
import {Provider as RollbarProvider, ErrorBoundary} from '@rollbar/react';
import {Provider as ReduxProvider} from 'react-redux';
import {IntlProvider} from 'react-intl';
import {ErrorComponent} from '@stjudecloud/ui-react';
import {mergeRight} from 'ramda';
import {RelayEnvironmentProvider} from 'react-relay';
import {
  PaginationModule,
  ModuleRegistry,
  ColumnAutoSizeModule,
  RowSelectionModule,
  ColumnApiModule,
  QuickFilterModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
} from 'ag-grid-community';

import graphqlEnvironment from './graphqlEnvironment';

import defaultLocaleData from './locale/en-US';
import customLocaleData from './locale/en-US-custom';
import configureStore from './store/configureStore';
import App from './components/App';
import DatabrowserFiltersProvider from './components/atoms/DatabrowserFiltersProvider';

import './theme/theme.scss';
import initializeDatadog from './datadog';

const env = process.env.NODE_ENV;

const rollbarConfig = {
  enabled: env === 'production' || env === 'staging',
  accessToken: document.querySelector("meta[name='rollbar-access-token']").content,
  environment: env,
  captureIp: 'anonymize',
};

if (env === 'production' || env === 'staging') {
  initializeDatadog();
}

// AgGrid global configuration
ModuleRegistry.registerModules([
  PaginationModule,
  QuickFilterModule,
  ColumnAutoSizeModule,
  RowSelectionModule,
  ColumnApiModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
]);

const localeData = mergeRight(defaultLocaleData, customLocaleData);
const store = configureStore();
const fallbackUI = () => <ErrorComponent variant="internalServerError" />;

const ReactApp = () => (
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary fallbackUI={fallbackUI}>
      <RelayEnvironmentProvider environment={graphqlEnvironment}>
        <IntlProvider locale="en" messages={localeData}>
          <ReduxProvider store={store}>
            <DatabrowserFiltersProvider>
              <App />
            </DatabrowserFiltersProvider>
          </ReduxProvider>
        </IntlProvider>
      </RelayEnvironmentProvider>
    </ErrorBoundary>
  </RollbarProvider>
);

export default ReactApp;
